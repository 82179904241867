html {
  scrollbar-gutter: stable;
  overflow-y: overlay;
  scroll-behavior: smooth;
}

body {
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-height: var(--doc-height);
  margin: 0;
  padding: 0;
  transition: all 0.25s linear;
  font-family: "neue-haas-grotesk-display", sans-serif;
  font-weight: 500;
  font-style: normal;
  word-spacing: 1px;
  position: relative;
  scroll-behavior: smooth;
  line-height: 1.5;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  scroll-margin-top: 60px;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1,
h2,
h3 {
  font-family: "neue-haas-grotesk-text", sans-serif;
  font-weight: 700;
  font-style: normal;
}

i {
  font-weight: normal;
  font-style: normal;
  font-size: 22px;
  line-height: 1;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

/* ReactBootstrap modal animation override */
@media screen and (max-width: 576px) {
  .modal.show .modal-dialog {
    transform: translateY(-50%) !important;
  }
}

@media screen and (max-width: 576px) {
  .modal.fade .modal-dialog {
    transform: translateY(-50%) !important;
  }
}

/* prismjs theme override */
code[class*="language-"],
pre[class*="language-"] {
  background: unset !important;
}

.custom-html-style {
  padding-bottom: 100px;
}
